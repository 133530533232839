











import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { fetchServices } from '@elitepage/api/services'
import PServiceBox from '@elitepage/components/PServiceBox.vue'

@Component({ components: { PServiceBox } })
export default class Services extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']
    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer
    @ElitepageStore.Action('fetchSingleProducer') fetchSingleProducer: (producerSlug: IProducer['slug']) => void

    services: IService[] = []

    async mounted() {
        this.services = await fetchServices(this.producer.id)
    }
}
